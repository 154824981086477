import React from 'react'
import './MenuBar.css'

import plasees_logo from '../../images/plasees_logo.png'

const MenuBar = () => {
    return (
        <nav className="header">
            <div className="nav-wrapper">
               {/* <a className="logo" href='/'>Your Logo</a> */} 
                <img src={plasees_logo} alt="PlaSees Logo"/>
                <input className="menu-btn" type="checkbox" id="menu-btn"/>
                <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>

                <ul className="menu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
                    <li><a href="/ContactUs">Contact Us</a></li>        
                   {/**   <li><a href="/AboutUs">About Us</a></li> */}       
                </ul>
            </div>
        </nav>
    )
}

export default MenuBar;