//import logo from './logo.svg';
import React from 'react'
import './App.css';
import {
  BrowserRouter as Router, 
  Routes,
  Route
} from 'react-router-dom'

import Layout from './layout/Layout'
import Home from './components/pages/Home'
import AboutUs from './components/pages/AboutUs'
import ContactUs from './components/pages/ContactUs'
import PrivacyPolicy from './components/pages/PrivacyPolicy'

function App() {
  return (
    <div className="body-wrap">
      <Router>
        <Layout>
          <Routes>
            <Route path={'/AboutUs'} element={<AboutUs/>}/>
            <Route path={'/ContactUs'} element={<ContactUs/>}/>
            <Route path={'/PrivacyPolicy'} element={<PrivacyPolicy/>}/>
            <Route path={'/'} element={<Home/>}/>
          </Routes> 
        </Layout>
      </Router>
    </div>
  );
}

export default App;
