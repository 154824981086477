import React from 'react'
import img_ob_categories from '../../images/img_ob_categories.png'
import img_ob_explore from '../../images/img_ob_explore.png'
import img_ob_how from '../../images/img_ob_how.png'
import img_ob_plasees from '../../images/img_ob_plasees.png'
import './Features.css'


const Features = () => {
    return (
        <React.Fragment>
            <section className="content-container">
                <div className="textArea"> 
                 {/*   <h1>Features</h1>
                    <p>What others say about us.</p> */} 
                </div>
                <div className="cust-container">
                    <div>
                        <img src={img_ob_plasees} alt="PlaSees"/>
                    </div>
                    <div className='cust-container-flex-item'>
                        <p><h1>PlaSees</h1></p>
                        <p>Welcome to PlaSees! A fun way to find places of interest near you using Augmented Reality.</p>
                    </div>
                </div>

                <div className="reversed">
                    <div>
                        <img src={img_ob_how} alt="PlaSees" />
                    </div>
                    <div className='cust-container-flex-item-reversed'>
                        <p><h1>How does it work?</h1></p>
                        <p>PlaSees uses your user location to find places around you. These places are then augmented into your reality via your camera.</p>
                    </div>
                </div>

                <div className="cust-container">
                    <div>
                        <img src={img_ob_categories} alt="PlaSees" />
                    </div>
                    <div className='cust-container-flex-item'>
                        <p><h1>Find places by category</h1></p>
                        <p>PlaSees allows you to find different places near you based on your desired category type. There are over 40 category types to choose from.</p>
                    </div>
                </div>

                <div className="reversed">
                    <div>
                        <img src={img_ob_explore} alt="PlaSees" />
                    </div>
                    <div className='cust-container-flex-item-reversed'>
                        <p><h1>Explore</h1></p>
                        <p>Not looking for anything in particular? PlaSees allows you to explore based on your mood. There are 6 moods to select from.</p>
                    </div>
                </div>

            </section>
        </React.Fragment>
    )
}

export default Features;