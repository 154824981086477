import React from 'react'

import Header from '../misc/Header'
import Features from '../misc/Features'

const Home = () => {
    return (
        <React.Fragment>
            <Header />
            <Features />
        </React.Fragment>
    )
}

export default Home;

